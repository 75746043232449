import Breadcrumb from 'react-bootstrap/Breadcrumb'
import PropTypes from "prop-types";
import { Container } from 'react-bootstrap';

 function Breadcrumbs(props) {
    let breadcrumbs = <></>;
	const content = [];

	let bcscript = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": []
	}
	let crumbs_list = 
		{
			"@type": "ListItem",
			"position": 0,
			"name": "",
			"item": ""
		}
		
		for (let index = 0 ; index <  props.crumbs.length; index++ ) {
			const crumb = props.crumbs[index]
			crumbs_list["position"] = index
			crumbs_list["name"] = crumb[1]
			crumbs_list["item"] = "https://www.calirextn.com" + crumb[0]
			bcscript.itemListElement.push(crumbs_list);
			const crumb_item = (			
				<Breadcrumb.Item className={props.invertedText ? "is-white" : false } active={index === props.crumbs.length-1 ? true : false } href={crumbs_list["item"]}>
					{crumbs_list["name"]}
				</Breadcrumb.Item>

			);
			content.push(crumb_item);
			
		}
		const script_content = JSON.stringify(bcscript)
		console.log(script_content)
		breadcrumbs = (
			<>
			<Container>
			<Breadcrumb>
				{content}
			</Breadcrumb>
			</Container>
			</>
		) 
	

	return (
		<>
			{breadcrumbs}
			<script type="application/ld+json" dangerouslySetInnerHTML={{__html: script_content}}></script>
		</>
	);
}

Breadcrumbs.propTypes = {
	crumbs: PropTypes.array,
	invertedText : PropTypes.string
  };
export default Breadcrumbs







