import React, { useState } from "react";
import PropTypes from "prop-types";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faClipboardList } from '@fortawesome/free-solid-svg-icons'
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import PopupForm from '../popupform/PopupForm';
import {Helmet} from "react-helmet";
import {Container, Button,Modal} from 'react-bootstrap';
import {getToken,getCustomerslist} from '../../api/Common';
import {generateDeliverySlip,SaveData,generateBill} from '../../api/DataManager';
import PrintComponent from '../../components/printComponent/PrintComponent';
import {useHistory} from "react-router-dom";

function Table(props) {
	const { SearchBar } = Search;
	const { ToggleList } = ColumnToggle;
	let history = useHistory();
	// handle click event of logout button
	if (!getToken()){
		history.push("/login")
	}
	const [show, setShow] = useState(false);
	const handleShow = () => {
		confirmDelete()
		setShow(true);
	}
	const handleClose = () => {
		setShow(false);
		}
	const customersList = JSON.parse(getCustomerslist())
	const css = `
		.App {
			background: url('https://calirextn.com/assets/images/loading-background-light.svg');
			background-position: bottom right;
			background-size: cover;
			background-repeat: no-repeat;
		}
		`
	const customFormats = props.customFormats 
	const onSubmit = props.onSubmitFunction 
	const products = props.data
	let modifiedItems = []
	const columns = props.columns
	const custoColumns = props.togglecolumns
	const title = props.title
	const [inputList, setInputList] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [printButton, setPrintButton] = useState(false);
	const [alertDelete, setAlertDelete] = useState("Voulez-vous supprimer ces éléments");
	columns.forEach(element => {
		element.filter=textFilter({placeholder:"..."})
	});
	const generatePdf = () =>{
		console.log(selectedItems)
		if(selectedItems.length>0){
		generateDeliverySlip(selectedItems,products,inputList, setInputList,customersList)
		setPrintButton(true)
		}
	} 
	const generateBillPdf = () =>{
		console.log(selectedItems)
		generateBill(selectedItems,products,inputList, setInputList,customersList)
		setPrintButton(true)
	} 
	const handleOnSelect = (row, isSelect) => {
		let item = 0
		console.log(row)
		console.log(isSelect)
		for (let i=0; i<products.length; i++){
			if (products[i].id===row.id){
				item = i+1
				console.log(item)
			}
		}
		if (isSelect) {			
			setSelectedItems(selectedItems.concat(item))
			console.log(selectedItems)
			console.log('selected')
		}else{
			setSelectedItems(selectedItems.filter(i => i !== item))
		}
		console.log(selectedItems)
		return true; // return true or dont return to approve current select action
	}
	const handleMultipleOnSelect = async (row) => {
		let item = 0
		console.log(row)
		for (let i=0; i<products.length; i++){
			if (products[i].id===row.id){
				item = i+1
				console.log(item)
			}
		}
		return item
	}

	const handleOnSelectAll = async (isSelect, rows) => {
			Promise.all(rows.map(async (row) => { 
				return await handleMultipleOnSelect(row)
			})).then((ret)=>{
				if(isSelect){
					setSelectedItems(selectedItems.concat(ret))
				}else{
					setSelectedItems(selectedItems.filter(i => !ret.includes(i)))
				}
			})
		
	}



	const modifyItems = () =>{
		SaveData (modifiedItems,products,onSubmit)
	}
	const confirmDelete = ()=>{
		let warning = ""
		let msg = ""
		selectedItems.forEach(index => {
			if(products[index-1]["ongoing_orders"]==0 ||  products[index-1]["status"]=="terminé"||products[index-1]["status"]=="en attente d'enlèvement"){
				products[index-1]["memory"] = 0
				msg = msg +(msg!=""?", ":"") + products[index-1]["id"]
			}else{
				warning = warning +(warning!=""?", ":"") + products[index-1]["id"]
			}
			if (warning ==""){
				setAlertDelete(alertDelete + ": "+ msg)
			}else{
				setAlertDelete("Ces éléments ne peuvent pas être supprimés" + ": "+ warning+". Voulez-vous supprimer les éléments suivants: "+msg)
			}
			
		});
	}
	const deleteItems = () =>{
		setAlertDelete("Voulez-vous supprimer ces éléments")
		console.log(selectedItems)
		SaveData (selectedItems,products,onSubmit)
		handleClose()
	}

	const beforeSaveCell= (oldValue, newValue, row, column, done) =>{
		for (let i=0; i<products.length;i++){
			if(products[i].id === row.id){
				products[i][column["dataField"]]= newValue
				for (let j=0; j<modifiedItems.length;j++){
					if(modifiedItems[j]=== (i+1)){
						console.log(modifiedItems)
						done(true);
						return { async: true };
					}
					console.log(modifiedItems)
				}
				modifiedItems.push(i+1)
				console.log(modifiedItems)
			}
		}
		console.log(modifiedItems)
		done(true);
		return { async: true };
	}
	const CustomToggleList = ({

		onColumnToggle,
		toggles
		}) => (
		<div className="btn-group btn-group-toggle btn-group-vertical" style={{ flexDirection: 'row' }} data-toggle="buttons">
			{
			custoColumns
				.map(column => ({
				...column,
				toggle: toggles[column.dataField]
				}))
				.map(column => (
				<Button
					style={{ maxWidth: '200px',marginRight: '0.5rem !important',borderRadius: '0.3rem', height: '100%'}}
					type="button"
					variant="outline-primary"
					key={ column.dataField }
					className={ `toggle-button ${column.toggle ? 'active' : ''}` }
					data-toggle="button"
					aria-pressed={ column.toggle ? 'true' : 'false' }
					onClick={ () => onColumnToggle(column.dataField) }
				>
					{ column.text }
				</Button>
				))
			}
		</div>
		);	
	const selectRow = {
		mode: 'checkbox',
		clickToSelect: true,
		clickToEdit: true,
		onSelect: handleOnSelect,
		onSelectAll: handleOnSelectAll
	};
	const cellEdit = {
		mode: 'click',
		beforeSaveCell
	};
	const actions = props.actions
	const searchloop = (<FontAwesomeIcon icon={faSearch} />);
	const titleIcon = (<FontAwesomeIcon icon={faClipboardList} />);
	const modalSchema = props.schema
	const test = props.columnToggleProps
	console.log(test)
	const requiredFields = props.requiredFields
	const additionalProperties = props.additionalProperties
	return (
		<>
		<Helmet>
			<style>{css}</style>
		</Helmet>
		<Container className='p-5 info-table'>		
			<ToolkitProvider
				bootstrap4
				keyField={columns[0]["dataField"]}
				className='bootstrap-data-table' 
				data={ products } 
				columns={ columns } 
				columnToggle
				placeholder= ""
				search
			>
			{
				(props) => (
					
				<div	>
					<h3>{titleIcon}{title}</h3>
					{searchloop}
					
					<SearchBar { ...props.searchProps } placeholder='Recherche ...' />
					<div className="row p-3">
						<p className="is-size-11 has-text-centered"><b>Afficher/Cacher le(s) colonne(s)</b></p>
					<CustomToggleList { ...props.columnToggleProps } />
					</div>
					<div className="mb-2">
						<div className='row'>
						<div className='col p-2 has-text-centered-mobile'
						style={{ textAlign: 'start'}}>
						{actions.includes("generate")
							?<><Button variant="outline-primary" size="lg" onClick={generatePdf}>Générer le(s) bordereau(x)</Button>{' '}</>
							:<></>
						}
						{actions.includes("bill")
							?<><Button variant="outline-primary" size="lg" onClick={generateBillPdf}>Générer le(s) facture(s)</Button>{' '}</>
							:<></>
						}
						</div>
						{actions.includes("add")
							?<>
							<div className='col-md-auto p-2 has-text-centered-mobile'
							style={{ textAlign: 'end'}}>
							<PopupForm schema={modalSchema} requiredFields={requiredFields} additionalProperties={additionalProperties} customFormats={customFormats}	onSubmitFunction={onSubmit} color="primary" modalTitle="Ajouter un element" buttonTitle="+Ajouter"></PopupForm>{' '}
							</div></>
							:<></>
						}
						{actions.includes("modify")
							?<>
							<div className='col-md-auto p-2 has-text-centered-mobile'
							style={{ textAlign: 'end'}}>
							<Button variant="outline-primary" size="lg" onClick={modifyItems}>Modifier</Button>{' '}
							</div>
							</>
							:<></>
						}

						{actions.includes("delete")
							?
							<div className='col-md-auto p-2 has-text-centered-mobile'
							style={{ textAlign: 'end'}}>						
							<Button variant="outline-danger" size="lg"onClick={handleShow}>Supprimer</Button>
							<Modal show={show} onHide={handleClose}>
								<Modal.Header closeButton>
								<Modal.Title>Supprimer</Modal.Title>
								</Modal.Header>
								<Modal.Body>{alertDelete}</Modal.Body>
								<Modal.Footer>
								<Button variant="danger" onClick={handleClose}>
									Annuler
								</Button>
								<Button variant="primary" onClick={deleteItems}>
									Confirmer
								</Button>
								</Modal.Footer>
							</Modal>
							</div>
							:<></>
						}
						</div>
					</div>
					<BootstrapTable
						{ ...props.baseProps }
						pagination={ paginationFactory() }
						filter={ filterFactory() }
						selectRow={ selectRow }
						cellEdit={ cellEditFactory(cellEdit)}
					/>
					<PrintComponent componentToPrint={inputList} printButton={printButton}/>
				</div>
				)
			}
			</ToolkitProvider>

		</Container>
		{props.crumbs_list
			? <Breadcrumbs crumbs ={props.crumbs_list} invertedText={false} ></Breadcrumbs>
			: <></>
		}
		
		</>
	);
}
Table.propTypes = {
		additionalProperties: PropTypes.array,
		requiredFields: PropTypes.array,
		customFormats: PropTypes.array,
		togglecolumns: PropTypes.array,
		title: PropTypes.string,
		data: PropTypes.array,
		columns: PropTypes.array,
		schema: PropTypes.array,
		actions: PropTypes.array,
		crumbs_list: PropTypes.array,
		searchProps: PropTypes.any,
		columnToggleProps: PropTypes.any,
		onColumnToggle: PropTypes.any,
		toggles: PropTypes.any,
		baseProps: PropTypes.any,
		addItems: PropTypes.any,
		modifyItems: PropTypes.any,
		onSubmitFunction : PropTypes.string,
		deleteItems: PropTypes.any,
};


export default Table;



