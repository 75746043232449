import Table from '../table/Table';
import  customerData  from '../data/customers.json';
import {getCustomerslist,getOrderslist}  from '../../api/Common';
function Customers() {
	const customers = JSON.parse(getCustomerslist())
	const orders = JSON.parse(getOrderslist())
	console.log(customers)
	let list = []
	for (let i in customers){
		if(customers[i]["memory"]=="1"){
			let total_orders = 0
			let current_orders = 0
			for (let j in orders){
				if(orders[j]["client_id"]==customers[i]["id"]){
					total_orders = total_orders+1
					if(customers[i]["status"]!="terminé"){
						current_orders = current_orders +1
					}
				}
			}
			customers[i]["total_orders"] = total_orders
			customers[i]["ongoing_orders"] = current_orders
			list.push(customers[i])
		}
	}

	const schemaString = {
		firstname: {"type": "string", "title": "Prénom"},
		name: {"type": "string", "title": "Nom de famille"},
		email: {"type": "string", "title": "Adresse mail"},
		postal_code: {"type": "string", "title": "Code Postal"},
		state: {"type": "string", "title": "État/Commune"},
		address: {"type": "string", "title": "Adresse"},
		phone: {"type": "number", "title": "Numéro de téléphone",format: 'phone-tn'}
	}
	const requiredFields = {
		firstname: {"type": "string", "title": "Prénom"},
		name: {"type": "string", "title": "Nom de famille"},
		postal_code: {"type": "string", "title": "Code Postal"},
		state: {"type": "string", "title": "État/Commune"},
		address: {"type": "string", "title": "Adresse"},
		phone: {"type": "number", "title": "Numéro de téléphone",format: 'phone-tn'}
	}
	const customFormats = {
		'phone-tn': /^[0-9]{8}$/
	};
	return(
		<>
		<Table data={list} schema={schemaString} togglecolumns={customerData["togglecolumns"]}  requiredFields={requiredFields} customFormats={customFormats} onSubmitFunction="customers" actions={customerData["actions"]}  columns={customerData["columns"]} title={customerData["title"]} crumbs_list={customerData["crumbs"]} ></Table>
		</>
	);
}

export default Customers;
