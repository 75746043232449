import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
export default function PrintComponent(props) {
  let componentRef = useRef();

  return (
    <>
      <div >
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => <div className="p-3"><Button className={props.printButton? "":"is-hidden"}>Imprimer</Button></div>}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <div className="to-print" ref={(el) => (componentRef = el)} >
          <style type="text/css" media="print">{"@page { size: A4 ; pageBreakBefore: 'always'}"}</style>
          {props.componentToPrint}
        </div>
      </div>
    </>
  );
}

PrintComponent.propTypes = {
    componentToPrint: PropTypes.any,
    printButton: PropTypes.any
  };
  