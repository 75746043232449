import {Navbar, Container, Nav, NavDropdown} from 'react-bootstrap'
import PropTypes from "prop-types";
import './Header.scss';

function HeaderOut(props) {
	const loggedOut = <Nav><Nav.Link href="https://delivery.calirextn.com/">Connexion</Nav.Link>{/*<Nav.Link href="https://delivery.calirextn.com/">S&#39;inscrire</Nav.Link>*/}</Nav>

    return (
        <>
        <Navbar collapseOnSelect expand="lg" bg={props.bg} variant="dark">
        <Container className="page-header" >
        <Navbar.Brand href="/">
                <img
                alt=""
                src="https://calirextn.com/assets/images/bare-logo-ctn.svg"
                width="70"
                height="70"
                className="d-inline-block align-top"
                />{' '}
            </Navbar.Brand>
        <Navbar.Brand  className="mea-text" href="/">Calirex Tunisie</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" >
            <Nav className="me-auto">
            <Nav.Link href="https://delivery.calirextn.com/">S&#39;enregistrer</Nav.Link>
			{/* <Nav.Link href="/tracking">Suivre un colis</Nav.Link> */}
            </Nav>

			{loggedOut}
            
        </Navbar.Collapse>
        </Container>
        </Navbar>      
        </>  
    );
}

HeaderOut.propTypes = {
    bg: PropTypes.array
};

export default HeaderOut;
