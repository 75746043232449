import React, {useState } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import './Prices.scss';

function Prices() {   
	let table_data = require('../data/prices.json'); 
	let title = "Choisissez l'offre qui vous convient";
  const [show, setShow] = useState(false);
  const css = `
      .App {
          background: url('https://calirextn.com/assets/images/loading-background.svg');
          background-position: bottom right;
          background-size: cover;
          background-repeat: no-repeat;
      }
  `
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const cards = [];
    for (let card of table_data.cards) {
      const criterias = []
      
      for (let criterea of card.criterias) {
      const criterias_item = (
        <li className="list-group-item price-card-bullet">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check text-primary" viewBox="0 0 16 16">
            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
          </svg> {criterea}
        </li>
      )
      criterias.push(criterias_item);
      }
		const card_item = (

        <div className="col-lg-4 col-md-12 mb-4">
          <Card className="popout-card h-100 shadow-lg pricing-card">
            <Card.Body>
              <span className="badge text-primary badge-card is-size-13">{card.promo}</span>
              <div className="text-center p-3">
                <br/>
                <Card.Title className="is-size-20 text-primary text-center price-card-title" dangerouslySetInnerHTML={{__html: card.name}}></Card.Title>
                <br/>
                <small className="text-tertiary is-size-11">{card.quantity}</small>
                <br/><br/>
                <span className="h1">{card.price}</span><sup>TND</sup>/colis
                <br/><br/>
              </div>
              <ul className="list-group list-group-flush">
                {criterias}
              </ul>
            </Card.Body>
            <Card.Footer className=" text-center price-card-footer">
              <button className="btn btn-primary btn-card btn-lg" onClick={handleShow}>Selectionner</button>
            </Card.Footer>
          </Card>
        </div>
		);
      cards.push(card_item);
    }
    return (
        <> 
          <Helmet>
              <style>{css}</style>
          </Helmet>
          <div classNameName= "Prices">
            <div className="container-fluid price-cards-container" >
              <div className="container p-4">
                <h2 className=" text-center text-white p-4">{title}</h2>
                <div className="row">
                  {cards}
                </div>    
              </div>
            </div>
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>Woohoo, you&#39;re reading this text in a modal!</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </>  
    );
}


export default Prices;
