import { Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
const imgStyle = {
	padding: " 2rem "
};
const hrStyle ={
	width: "50%",
	"display" : "inline-block"
}
const Map = (props) => (
	<Container className="has-text-centered" style={imgStyle}>
		<hr  style={hrStyle}/>
		<Row>
			<p className="is-size-24 is-bold">{props.title}</p>
			<p className="is-size-15">{props.subtitle}</p>
		</Row>
		<Row>
		<a data-expand-link className="link" rel="noopener noreferrer" target="_blank" href="https://www.google.com/maps/place/Calirex+Tunisie/@36.7643632,10.0340955,15z/data=!4m2!3m1!1s0x0:0xe8edc1e22de4a228?sa=X&ved=2ahUKEwjgvoCwnJvzAhUkBGMBHX4YCpIQ_BJ6BAhJEAU">

			<img data-expand-target loading="lazy" className="standalone-img" src={props.src} alt={props.alt}></img></a>
		</Row>
	</Container>
	)
	Map.propTypes = {
		title: PropTypes.string,
		subtitle: PropTypes.string,
		src: PropTypes.string,
		alt: PropTypes.string
	};
export default Map;
