import Table from '../table/Table';
import	billsData	from '../data/bills.json';
import  plans  from '../data/plans.json';
import {getCustomerslist,getOrderslist,getUserPlan}	from '../../api/Common';
function Bills() {
	const orders = JSON.parse(getOrderslist())
	let data = []
	let list = []
	const plan = getUserPlan()
	let initProperties = ["delivered","awaiting_pickup","in_delivery","postponed","in_storage","awaiting_return","in_storage_to_return","in_storage_to_return",
	"return_postponed","return_pickup_postponed","returned","package_number","total_price","total_weight","base_price_delivery","custom_delivery_price","total_packages","payed"]
	const dataMap = async () =>{
		Promise.all(orders.map(item => { 
			if(item["memory"]=="1"){
				if(!list[item["order_id"]]){
				list[item["order_id"]]= []
				list[item["order_id"]]["order_id"] = item["order_id"]
				initProperties.forEach(element => {
					list[item["order_id"]][element] = 0
				});
				}
				if(item["order_id"]==list[item["order_id"]]["order_id"]){
		
				switch (item["status"]) {
					case "livré":
						list[item["order_id"]].delivered = list[list[item["order_id"]]].delivered + 1
						break;
					case "en attente d'enlèvement":
						list[item["order_id"]].awaiting_pickup = list[item["order_id"]].awaiting_pickup + 1
						break;
					case "en cours de livraison":
						list[item["order_id"]].in_delivery = list[item["order_id"]].in_delivery + 1
						break;
					case "Livraison reportée":
						list[item["order_id"]].postponed = list[item["order_id"]].postponed + 1
						break;
					case "en entrepôt":
						list[item["order_id"]].in_storage = list[item["order_id"]].in_storage + 1
						break;
					case "en attente de retour":
						list[item["order_id"]].awaiting_return = list[item["order_id"]].awaiting_return + 1
						break;
					case "en entrepôt (retour)":
						list[item["order_id"]].in_storage_to_return = list[item["order_id"]].in_storage_to_return + 1
						break;
					case "retour reporté":
						list[item["order_id"]].return_postponed = list[item["order_id"]].return_postponed + 1
						break;
					case "enlèvement reporté (retour)":
						list[item["order_id"]].return_pickup_postponed = list[item["order_id"]].return_pickup_postponed + 1
						break;
					case "retourné":
						list[item["order_id"]].returned = list[item["order_id"]].returned + 1
						break;
					case "problème de livraison":
						list[item["order_id"]].returned = list[item["order_id"]].returned + 1
						break;
					case "payé":
						list[item["order_id"]].payed = list[item["order_id"]].payed + 1
						break;
					default:
					console.log("Status not recognized");
				}
				item["base_price_delivery"]=(plans[plan]["price"]+((item["weight_g"]>10200)?(Math.ceil((item["weight_g"]-10200)/1000) * plans[plan]["extra"]):""))
				list[item["order_id"]].total_packages = list[item["order_id"]].total_packages + 1
				list[item["order_id"]].total_weight = Number(item["weight_g"]) + list[item["order_id"]].total_weight
				list[item["order_id"]].total_price = Number(item["price_base"]) + list[item["order_id"]].total_price
				list[item["order_id"]].base_price_delivery = Number(item["base_price_delivery"]) + list[item["order_id"]].base_price_delivery
				list[item["order_id"]].custom_delivery_price = Number(item["custom_delivery_price"]) + list[item["order_id"]].custom_delivery_price
				list[item["order_id"]].package_number = Number(item["package_number"]) + list[item["order_id"]].package_number
				}
			}
		})).then((ret)=>{
			console.log("end of promises", orders)
			console.log("end of promises", ret)
			console.log("end of promises", list)
			return true
		})
	}
	dataMap()
	for (let k in list){
		data.push(list[k])
		console.log(data)
	}

	console.log(data)
	return(
		<>
		<Table data={data} actions={billsData["actions"]} togglecolumns={billsData["togglecolumns"]} columns={billsData["columns"]} title={billsData["title"]} crumbs_list={billsData["crumbs"]} ></Table>
		</>
	);
}

export default Bills;
