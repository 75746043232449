import Slider from '../slider/Slider';
import Cards from '../cards/Cards';

import { Container } from "react-bootstrap";
import IntroCard from '../introCard/IntroCard';
import Map from '../map/Map';
import './Home.scss';


function Home() {
	const cards_data = require('../data/cards.json'); 
	const map_title = "Vous pouvez nous retrouver à cette adresse";
	const map_subtitle = "7 avenue habib bourguiba Mornaguia, Manouba, 1110 Tunisie";
	const card_img = "https://calirextn.com/assets/images/calirex-tunisie-geolocatisation.jpg";
    const cards = [];
    for (let card of cards_data.cards) {
		const card_item = (
			<Cards type={card.type} title={card.title} src={card.src} text_size={card.text_size} text={card.text} alt={(card.alt) ? card.alt : ''}></Cards>
		);
      cards.push(card_item);
    }
    return (
        <> 
			<IntroCard/>
			{cards}
			<Map title={map_title} subtitle={map_subtitle} src={card_img} alt="Calirex Tunisie address" />
			<div className='container'>
			</div>
        </>  
    );
}
export default Home;
