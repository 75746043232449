import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { removeUserSession, getToken } from '../../api/Common';
import './Dashboard.scss';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Doughnut, PolarArea } from 'react-chartjs-2';

  
function Dashboard() {
  const crumbs_list = [["/", "Accueil"], ["/Dashboard", "Tableau de bord"]]
  let history = useHistory();
  // handle click event of logout button
  if (!getToken()){
    removeUserSession();
	history.push("/login")
  }
  const goToOrders = () =>{
    history.push("/orders")
  }
  const css = `
    .App {
        background: url('https://calirextn.com/assets/images/loading-background-light.svg');
        background-position: bottom right;
        background-size: cover;
        background-repeat: no-repeat;
    }
    `
  ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);
  const fullDataSet = {
    labels: ['Livrées', 'en cours', 'En attente d\'enlevement', 'Retournées'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5],
        backgroundColor: [
            '#0943ea',
            '#eab009',
            '#ea6b09',
            '#7609ea',
        ],
        borderWidth: 1,
      },
    ],
  };
  const dualDataSetOrders = {
    labels: ['Livrées', 'Retournées'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19],
        backgroundColor: [
            '#0943ea',
            '#eab009',
        ],
        borderWidth: 1,
      },
    ],
  };
  const dualDataSetClients = {
    labels: ['Livrées', 'Retournées'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19],
        backgroundColor: [
            '#ea6b09',
            '#7609ea',
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      <Helmet>
          <style>{css}</style>
      </Helmet>
      <div className="p-5">
      <h3 className="has-text-centered">Suivez facilement l&#39;état de vos commandes!</h3>
      <Doughnut data={fullDataSet} onClick={goToOrders} />
      </div>
      <h3 className="has-text-centered">Ratio de vos commandes retournées vs livrées!</h3>
      <PolarArea data={dualDataSetOrders} />
      <div className="p-5">
      <h3 className="has-text-centered">Ratio de vos clients livrées vs en attente de livraison!</h3>
      <PolarArea data={dualDataSetClients} />    
      </div>
      <Breadcrumbs crumbs ={crumbs_list} invertedText={false} ></Breadcrumbs>  
    </>
  );
}


export default Dashboard;
