
import { Container, Row, Card, Col } from "react-bootstrap";
import './Cards.scss';
import Video from '../video/Video';
import PropTypes from "prop-types";

 function Cards(props) {
    let card = <></>;
	const hrStyle ={
		width: "50%",
		"display" : "inline-block"
	}
    if (props.type === 'video'){
		card = (
            <Card className={props.type}>
				<Card.Body>
					<Row>
						<Col>
							<Video embedId={props.src} /> 
						</Col>
					</Row>
				</Card.Body>
			</Card>	
      );
	}
	if (props.type === 'card-img-left'){
		card = (
			<Card className={props.type}>
				<Card.Body>
					<Row>
						<Col md={8}>
							<Card.Text className={props.text_size}> 
								<p className="is-size-20 is-bold">{props.title}</p>
								<p className="is-size-13">{props.text}</p>
							</Card.Text>
						</Col>
						<Col md={4}>
							<Card.Img loading="lazy"  src={props.src} alt={props.alt}/>
						</Col>
					</Row>
				</Card.Body>
			</Card>	
		) 
	}
	if (props.type === 'card-img-right'){
		card = (
			<Card className={props.type}>
				<Card.Body>
					<Row>
						<Col md={4}>
							<Card.Img  loading="lazy"  src={props.src} alt={props.alt}/>
						</Col>
						<Col md={8}>
							<Card.Text className={props.text_size}>
							<p className="is-size-20 is-bold">{props.title}</p>
								<p className="is-size-13">{props.text}</p>
							</Card.Text>
						</Col>
					</Row>
				</Card.Body>
			</Card>	
		) 
	}
	if (props.type === 'jumbotron'){
		card=(
			<>
			<h2 className="has-text-centered is-size-24 is-bold">Apprenez à nous connaître?</h2>
			{/*<div className="jumbotron jumbotron-fluid alert alert-primary">
				
			<p className="lead jumbottron-lead is-marginless">
				<Row>
				<Col md={1}></Col>
				<Col md={8}>
				<h1 className="display-4 is-size-18 is-bold">Envie de tarifs personnalisées?</h1>
				<p>Sélectionnez un parmi nos tarifs en promotion qui s&#39;adaptent à vos besoins quelle que soit la taille de votre commerce. <b>Commencez vos économies dès maintenant!</b></p>
				</Col>
				<Col md={2}>
				<a className="btn btn-primary btn-lg jumbotron-btn" href="/pricing" role="button">Tarifs</a></Col>
				<Col md={1}></Col>
				</Row>
			</p>
			</div>*/}
			</>
		)
	}
	return (
	<Container lg className="card-container" >
		{card}
	</Container>
	);
}

Cards.propTypes = {
	type: PropTypes.string,
	text_size : PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	src: PropTypes.string,
	alt: PropTypes.string
  };
export default Cards
