
import {getToken,updateOrderList,updateCustomersList, getUserSession} from './Common';
const CryptoJS = require("crypto-js");
const QRCode = require('qrcode.react');
const token = getToken()
const userInfo = getUserSession()

export const submitOrder = (formData, e, ordersList, dataTransfer, newUserTransfer, customersList) =>{ 
    const date = new Date()
    formData["delivery_date"] = ""
    formData["return_date"] = ""
    formData["memory"] = 1
    formData["TableName"] = token+"orders"
    formData["status"] = "en attente d'enlèvement"
    formData["address"] = "0"

    let Clientorder_exists = 0
    let order_exists = 0
    if((!formData["client_id"])&&(formData.addClient)){
      newUserTransfer= submitCustomer (formData.addClient[0], e, customersList,newUserTransfer)
      let userTransferObject = JSON.parse(newUserTransfer)
      formData["client_id"] = userTransferObject.id

      console.log(formData)
      console.log(typeof(formData) )
    }
    const oldData = JSON.parse("["+dataTransfer+"]")
    let local_Clientorder_exists = false
    for(let i=0; i<oldData.length;i++){

        if(oldData[i]["client_id"]===formData["client_id"]){
          local_Clientorder_exists = 1
          oldData[i]["package_number"] = parseInt(oldData[i]["package_number"]) + parseInt(formData["package_number"])
          oldData[i]["weight_g"] = parseInt(formData["weight_g"]) + parseInt(oldData[i]["weight_g"])
          oldData[i]["price_base"] = parseInt(formData["price_base"]) + parseInt(oldData[i]["price_base"])
        }
         
    }
    console.log(oldData.length)
    console.log(ordersList.length)

    for(let i=0; i<ordersList.length;i++){
      console.log(formData)

      if(ordersList[i]["status"]==="en attente d'enlèvement"){
        order_exists = i+1
        formData["id"] = ordersList[i]["id"]
        if(ordersList[i]["client_id"]===formData["client_id"]){
          Clientorder_exists = i+1
        }
      }

      if(i===ordersList.length-1){
        
        if (oldData.length===0){
          if(order_exists){
            formData["order_id"] = ordersList[order_exists-1]["order_id"]
            console.log(' formData["order_id"]')
            console.log( formData["order_id"])
          }else{
            formData["order_id"] = "CTO-"+ Date.now()
            console.log(" order doesn't exist")
          }
          if (Clientorder_exists){
            console.log("client order exist")
            formData["id"] = ordersList[Clientorder_exists-1]["id"]
            formData["package_number"] = parseInt(ordersList[Clientorder_exists-1]["package_number"]) + parseInt(formData["package_number"])
            formData["weight_g"] = parseInt(formData["weight_g"]) + parseInt(ordersList[Clientorder_exists-1]["weight_g"])
            formData["price_base"] = parseInt(formData["price_base"]) + parseInt(ordersList[Clientorder_exists-1]["price_base"])
            formData["creation_date"] = ordersList[Clientorder_exists-1]["creation_date"]
          }else{
            formData["creation_date"] = date.toISOString().split('T')[0].replace(/-/g,'/');
            formData["id"] = "CTP-"+ Date.now()
          }

          dataTransfer =JSON.stringify(formData)
        }else{
          if (local_Clientorder_exists){
            dataTransfer = JSON.stringify(oldData)
          }else{
            if (Clientorder_exists){
              formData["order_id"] = ordersList[order_exists]["order_id"]
              formData["id"] = ordersList[Clientorder_exists-1]["id"]
              formData["package_number"] = parseInt(ordersList[Clientorder_exists-1]["package_number"]) + parseInt(formData["package_number"])
              formData["weight_g"] = parseInt(formData["weight_g"]) + parseInt(ordersList[Clientorder_exists-1]["weight_g"])
              formData["price_base"] = parseInt(formData["price_base"]) + parseInt(ordersList[Clientorder_exists-1]["price_base"])
              formData["creation_date"] = ordersList[Clientorder_exists-1]["creation_date"]
            }else{
              formData["order_id"] = oldData[0]["order_id"]
              formData["id"] = "CTP-"+ Date.now()
              formData["creation_date"] = date.toISOString().split('T')[0].replace(/-/g,'/');
              
            }
            dataTransfer =dataTransfer +","+ JSON.stringify(formData)
          }
        }


      }
    }
    if (ordersList.length === 0){
      if (local_Clientorder_exists){
        dataTransfer = JSON.stringify(oldData)
      }else{
        formData["id"] = "CTP-"+ Date.now()
        formData["creation_date"] = date.toISOString().split('T')[0].replace(/-/g,'/');
        if (oldData.length===0){
          formData["order_id"] = "CTO-"+ Date.now()
          dataTransfer =JSON.stringify(formData)
        }else{
          formData["order_id"] = oldData[0]["order_id"]
          dataTransfer =dataTransfer +","+ JSON.stringify(formData)
        }
      }
    }
    return [dataTransfer,newUserTransfer]
    
  }
export  const submitCustomer = (formData, e, customersList,dataTransfer) =>{ 
    formData["memory"] = 1
    formData["TableName"] = token+"customers"
    formData["country_code"] = "+216"
    console.log(formData)
    console.log(customersList)
    let userExists = false
    for(let i=0; i<customersList.length;i++){
      if(customersList[i]["email"]===formData["memory"] ){
        userExists = i
        alert("Ce client a déjà été créé")
      }
    }
    if(!userExists){
      formData["id"] =  "CTCID-"+ Date.now()
      console.log(formData[0])
      console.log(dataTransfer)
      dataTransfer =dataTransfer +(dataTransfer==="" ?"":",") + JSON.stringify(formData)
      console.log(JSON.stringify(formData))
      
    }
    Object.keys(formData).map(function(key, index) {
      formData[key] = "";
    });
    console.log(e)
    console.log(dataTransfer)
    return dataTransfer
  }

  export const generateBill = (selectedItems,products, inputList, setInputList,customersList)=>{

    console.log("userInfo[0]")
    console.log(userInfo)
    let newImputList = []
    console.log(products)
    for (let select of selectedItems) {
        let date = new Date() 
        let today = date.toISOString().split('T')[0].replace(/-/g,'/');
        let weight =  products[select-1]["total_weight"]
        let order =  products[select-1]["order_id"]
        let nb_colis = products[select-1]["package_number"]
        let text = token + products[select-1]["id"]+ products[select-1]["order_id"]
        let ciphertext = CryptoJS.AES.encrypt(text, '-tracking-number-').toString();
        let raw_product_price = (products[select-1]["total_price"]*0.93).toFixed(3)
        let tva_product_price = (products[select-1]["total_price"]*0.07).toFixed(3)
        let product_price = products[select-1]["total_price"]
        let raw_delivery_price = ((products[select-1]["price_delivery"])*0.93).toFixed(3)
        let tva_delivery_price = ((products[select-1]["price_delivery"])*0.07).toFixed(3)
        let delivery_price = (products[select-1]["price_delivery"])
        let raw_custom_delivery_price = ((products[select-1]["delivery_price"])*0.93).toFixed(3)
        let tva_custom_delivery_price = ((products[select-1]["delivery_price"])*0.07).toFixed(3)
        let custom_delivery_price = (products[select-1]["delivery_price"])
        let raw_total_price = Number(raw_product_price) + Number((!isNaN(raw_delivery_price))? raw_delivery_price : 0) + Number((!isNaN(raw_custom_delivery_price))? raw_custom_delivery_price : 0) 
        let tva_total_price = Number(tva_product_price) + Number((!isNaN(tva_delivery_price))? tva_delivery_price : 0) + Number((!isNaN(tva_custom_delivery_price))? tva_custom_delivery_price : 0) 
        let total_price = product_price + Number((!isNaN(delivery_price))? delivery_price : 0) + Number((!isNaN(custom_delivery_price))? custom_delivery_price : 0) 
        //log encrypted data
            let Input = () => {
                return <>
                        <div className="page-break" />
                        <table className="table table-bordered has-background-white">
                        <thead>
                            <tr>
                            <th scope="col">

                                <img alt="" src="https://calirextn.com/assets/images/logo-ct.svg" width="130px" />
                            </th>
                            <th scope="col">
                                <p className='is-size-9'>Societé de livraison: <u>Calirex Tunisie</u>
                                <br/><u>Site Web: </u>https://www.calirextn.com/
                                <br/><u>Adresse: </u>7 Avenue habib bourguiba, Mornaguia,<br/> Manouba 1110, Tunisie
                                <br/><u>Numéro de téléphone: </u> (+216) 50 873 992
                                <br/><u>Matricule fiscal: </u>1726761/C</p>

                            </th>
                            <th>
                              Date: {today}
                              <br/>
                              Manifest {order}
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td colSpan={2}> <b>Société: {userInfo[1][1]}</b>
                                <br/>Adresse: {userInfo[1][4]}, {userInfo[1][9]}, {userInfo[1][8]}, {userInfo[1][7]}
                                <br/>Numéro de téléphone: ({userInfo[1][11]}) {userInfo[1][2]}
                            </td>
                            <td>

                            </td>
                            </tr>

                            <tr>
                            <td><u>Prix du produit:</u> {product_price} 
                              <br/>prix(HT): {raw_product_price} TND
                                <br/>TVA(7%): {tva_product_price} TND
                                <br/>prix(TTC): {product_price} TND
                                <br/>
                                    <b>Prix de la livraison:</b>
                                <br/>prix(HT): {raw_delivery_price} TND
                                <br/>TVA(7%): {tva_delivery_price} TND
                                <br/>prix(TTC): {delivery_price} TND
                                <br/>
                                  
                                <b>Total à payer:</b>
                                <br/>prix(HT): {raw_total_price} TND
                                <br/>TVA(7%): {tva_total_price} TND
                                <br/>prix(TTC): {total_price} TND
                                <br/><u>Poids:</u> {weight} grammes
                                <br/><u>Nombre de colis:</u> {nb_colis}
                            </td>
                            <td>
                                Signature Calirex Tunisie: 
                            </td>
                            <td>
                                Signature {userInfo[1][1]} 
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </>;
            };

            newImputList.push(<Input />)
        
    }
    setInputList(newImputList);
}
export const generateDeliverySlip = (selectedItems,products, inputList, setInputList,customersList)=>{
    if(selectedItems.length>0){
      console.log("userInfo[0]")
      console.log(userInfo[0])
      let newImputList = []
      let nb_slip =1
      let global_price =0
      let global_weight =0
      let global_delivery =0
      let global_total =0
      let order =products[selectedItems[0]-1]['order_id']
      let global_packages =0
      let date = new Date() 
      let today = date.toISOString().split('T')[0].replace(/-/g,'/');

      for (let select of selectedItems) {
        let weight =  products[select-1]["weight_g"]
        let nb_colis = products[select-1]["package_number"]
        let text = token + products[select-1]["id"]+ products[select-1]["order_id"]
        let ciphertext = CryptoJS.AES.encrypt(text, '-tracking-number-').toString();
        let qrcode = "https://calirextn.com/tracking?tracking_number="+ciphertext
        //let raw_delivery_price = ((products[select-1]["price_delivery"])*0.93).toFixed(3)
        //let tva_delivery_price = ((products[select-1]["price_delivery"])*0.07).toFixed(3)
        let delivery_price = (products[select-1]["base_price_delivery"])
        //let raw_custom_delivery_price = ((products[select-1]["delivery_price"])*0.93).toFixed(3)
        //let tva_custom_delivery_price = ((products[select-1]["delivery_price"])*0.07).toFixed(3)
        let custom_delivery_price = (products[select-1]["delivery_price"])
        //let raw_product_price = (products[select-1]["price_base"]*0.93).toFixed(3)
        //let tva_product_price = (products[select-1]["price_base"]*0.07).toFixed(3)
        let product_price = products[select-1]["price_base"] - ((!isNaN(custom_delivery_price))? Number((!isNaN(delivery_price))? delivery_price : 0):0)
        //let raw_total_price = Number(raw_product_price) + Number((!isNaN(raw_delivery_price))? raw_delivery_price : 0) + Number((!isNaN(raw_custom_delivery_price))? raw_custom_delivery_price : 0) 
        //let tva_total_price = Number(tva_product_price) + Number((!isNaN(tva_delivery_price))? tva_delivery_price : 0) + Number((!isNaN(tva_custom_delivery_price))? tva_custom_delivery_price : 0) 
        let total_price = Number(product_price) + Number((!isNaN(delivery_price))? delivery_price : 0) + Number((!isNaN(custom_delivery_price))? custom_delivery_price : 0) 
        let client
        let address
        let number
        let email
        console.log(customersList)
        for(let j=0; j<customersList.length; j++){
            console.log(customersList[j]["id"])
            if(customersList[j]["id"]==products[select-1]["client_id"]){
                client = customersList[j]["firstname"] + " " + customersList[j]["name"]
                address = customersList[j]["address"] + ", "+customersList[j]["state"] + ", "+customersList[j]["postal_code"] 
                number = customersList[j]["country_code"] + " " + customersList[j]["phone"]
                email = customersList[j]["email"]
            }
        }
        //log encrypted data
        global_packages= global_packages + Number(nb_colis)
        global_total= global_total + Number(total_price)
        global_delivery= global_delivery + Number(delivery_price)
        global_weight= global_weight + (weight?Number(weight):0)
        global_price= global_price + Number(product_price) + Number((!isNaN(custom_delivery_price))? custom_delivery_price : 0) 
        for(let num_colis=1; num_colis<=nb_colis; num_colis++){
            let is_even = ((nb_slip % 2)<1)
            let pageBreak = is_even
            let Input = () => {
                return <>
                  {pageBreak
                    ?<p style={{pageBreakBefore: 'always'}}></p>
                    :<></>
                  }
                        <table className="table table-bordered has-background-white">
                        <thead>
                            <tr>
                            <th scope="col">

                                <img alt="" src="https://calirextn.com/assets/images/logo-ct.svg" width="130px" />
                            </th>
                            <th scope="col">
                                <p className='is-size-9'>Calirex Tunisie
                                <br/><u>Site Web: </u>https://www.calirextn.com/
                                <br/><u>Adresse: </u>7 Avenue habib bourguiba, Mornaguia,<br/> Manouba 1110, Tunisie
                                <br/><u>Numéro de téléphone: </u> (+216) 50 873 992
                                <br/><u>Matricule fiscal: </u>1726761/C</p>

                            </th>
                            <th scope="col"><QRCode value={qrcode} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td colSpan={2}><b>Destinataire</b>
                                <br/>Nom: {client}
                                <br/>Adresse: {address}
                                <br/>tél: {number}
                                <br/>e-mail: {email}
                            </td>
                            <td> <b>Expéditeur</b>
                                <br/>Société: {userInfo[1][1]}
                                <br/>Adresse: {userInfo[1][4]}, {userInfo[1][9]}, {userInfo[1][8]}, {userInfo[1][7]}
                                <br/>tél: ({userInfo[1][11]}) {userInfo[1][2]}
                            </td>
                            </tr>

                            <tr>
                            <td colSpan={2}>                               
                                <u>Total à payer:</u>
                                <br/>prix(TTC): {total_price} TND
                                    
                            </td>
                            <td>
                              <u>Poids:</u> {weight} grammes
                              <br/><u>Nombre de colis:</u> {num_colis}/{nb_colis}
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </>;
            };

            newImputList.push(<Input />)
            nb_slip++
            
        }
      }
      let Bill = () => {
          return <>
                  
                  <p style={{pageBreakBefore: 'always'}} className="has-text-centered is-bold is-size-18">Manifest</p>
                  <div className="page-break" />
                  <table className="table table-bordered has-background-white">
                  <thead>
                      <tr>
                      <th scope="col">

                          <img alt="" src="https://calirextn.com/assets/images/logo-ct.svg" width="130px" />
                      </th>
                      <th scope="col">
                          <p className='is-size-9'>Societé de livraison: <u>Calirex Tunisie</u>
                          <br/><u>Site Web: </u>https://www.calirextn.com/
                          <br/><u>Adresse: </u>7 Avenue habib bourguiba, Mornaguia,<br/> Manouba 1110, Tunisie
                          <br/><u>Numéro de téléphone: </u> (+216) 50 873 992
                          <br/><u>Matricule fiscal: </u>1726761/C</p>

                      </th>
                      <th>
                        Date: {today}
                        <br/>
                        Manifest {order}
                      </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                      <td colSpan={2}> <b>Société: {userInfo[1][1]}</b>
                          <br/>Adresse: {userInfo[1][4]}, {userInfo[1][9]}, {userInfo[1][8]}, {userInfo[1][7]}
                          <br/>Numéro de téléphone: ({userInfo[1][11]}) {userInfo[1][2]}
                      </td>
                      <td>

                      </td>
                      </tr>

                      <tr>
                      <td><u><b>Prix du produit:</b> </u>
                          {global_price} TND
                          <br/>
                              <b>Prix de la livraison:</b>{global_delivery} TND
                          <br/>
                            
                          <b>Total à payer:</b> {global_total} TND
                          <br/><u>Poids:</u> {global_weight} grammes
                          <br/><u>Nombre de colis:</u> {global_packages}
                      </td>
                      <td>
                          Signature Calirex Tunisie: 
                      </td>
                      <td>
                          Signature {userInfo[1][1]} 
                      </td>
                      </tr>
                  </tbody>
              </table>
          </>;
      };
      newImputList.push(<Bill />)
      setInputList(newImputList);
    }
}

export 	const SaveData= (items,products,onSubmit) =>{
    let data = []
    items.forEach(element => {
        console.log(element)
        data.push(products[element-1])
        if (onSubmit=== "orders"){
            data[data.length-1]["TableName"] = token+"orders"
        }
        if (onSubmit === "customers"){
            data[data.length-1]["TableName"] = token+"customers"
        }
    });
    const dataTransfer = JSON.stringify(data)
    if (onSubmit === "orders"){
        console.log(items)
        console.log(products)
        console.log(data)
        updateOrderList(token, '{"data":'+dataTransfer+'}' )
    }
    if (onSubmit === "customers"){
        updateCustomersList(token, '{"data":'+dataTransfer+'}' )
    }
}