import Table from '../table/Table';
import  customerData  from '../data/orders.json';
import  plans  from '../data/plans.json';
import {getToken, getCustomerslist, getUserIds,getOrderslist,getUserPlan}  from '../../api/Common';
import {useHistory} from "react-router-dom";
function Orders() {
	const orders = JSON.parse(getOrderslist())
	const customers = JSON.parse(getCustomerslist())
	const plan = getUserPlan()
	let history = useHistory();
	if (!getToken()){
		history.push("/login")
	}
	let list = []
	for (let i in orders){
		if(orders[i]["memory"]=="1"){
			list.push(orders[i])
			list[list.length-1]["base_price_delivery"]=(plans[plan]["price"]+((list[i]["weight_g"]>10200)?(Math.ceil((list[i]["weight_g"]-10200)/1000) * plans[plan]["extra"]):""))
		}

	}
	list.map((item) => { 
		let customer = customers.find(element => (element.id ===item.client_id))
		if(customer){
			item.state= customer.state;
			item.client_name = customer.firstname + " " +customer.name
			item.postal_code= customer.postal_code;
		}
	})
	let userDescription = []
	for (let i in customers){
		userDescription.push(customers[i].firstname + " " +customers[i].name+ ": " + customers[i].id)

	}
	const createReciept = (data)=>{
		createReciept()
	}
	let userIds = getUserIds();
	userIds = [null].concat(userIds)
	userDescription = ["Selectionnez un utilisateur"].concat(userDescription)
	console.log(userIds)
	const schemaString = {
		client_id: {"type": "string", "title": "Numéro du client", "enum": userIds, "enumNames": userDescription},
		addClient: {
			type: "array",
			items: {
				$ref: "#/definitions/Metric"
			},
			maxItems: 1,
			title: ""
			},
		weight_g: {"type": "number", "title": "Poids en grammes"},
		price_base: {"type": "number", "title": "Prix du produit"},
		delivery_price: {"type": "number", "title": "Prix de livraison personnalisé"},
		package_number: {"type": "number", "title": "Nombre de colis"}
	}

	const definitions = {
		Metric: {
			title: "Nouveau Client",
			type: "object",
			properties: {
				firstname: {"type": "string", "title": "Prénom"},
				name: {"type": "string", "title": "Nom de famille"},
				email: {"type": "string", "title": "Adresse mail"},
				postal_code: {"type": "string", "title": "Code Postal"},
				state: {"type": "string", "title": "État/Commune"},
				address: {"type": "string", "title": "Adresse"},
				phone: {"type": "number", "title": "Numéro de téléphone",format: 'phone-tn'}
			},
			required: ["firstname", "name", "postal_code", "state", "address", "phone"]
		},		
	}
	const requiredFields = {

		price_base: {"type": "number", "title": "Prix du produit"},
		package_number: {"type": "number", "title": "Nombre de colis"},

	}
	return(
		<>
		<Table data={list} schema={schemaString} togglecolumns={customerData["togglecolumns"]} requiredFields ={requiredFields} additionalProperties={definitions} customFormats={{}}  onSubmitFunction="orders" actions={customerData["actions"]}  columns={customerData["columns"]} title={customerData["title"]} crumbs_list={customerData["crumbs"]} ></Table>
		</>
	);
}

export default Orders;



