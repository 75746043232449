import React, {useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import {submitOrder,submitCustomer} from '../../api/DataManager';
import {getToken, getCustomerslist, getOrderslist, updateOrderList , updateCustomersList}  from '../../api/Common';
import Form from "@rjsf/core";
function PopupForm(props) {   
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  }
  const orderHandleSave = () => {
    updateOrderList(token, '{"data":['+dataTransfer+']}' )
    if(newUserTransfer!=""){
      updateCustomersList(token, '{"data":['+newUserTransfer+']}' )
    }
    setShow(false);
  }
  const customerHandleSave = () => {
    updateCustomersList(token, '{"data":['+dataTransfer+']}' )
    setShow(false);
  }
  console.log("props.onSubmitFunction" )
  console.log(props.onSubmitFunction )

  const customersList = JSON.parse(getCustomerslist())
  const ordersList = JSON.parse(getOrderslist())
  const token = getToken()
  const handleShow = () => setShow(true);
  const schema = {
    title: "Veuillez remplir le formulaire",
    type: "object",
    required: Object.keys(props.requiredFields),
    properties: props.schema,
    definitions: props.additionalProperties
  }
  const uiSchema = {
    "ui:options":  {
      "expandable": true
    }
  };
  let dataTransfer = ""
  let newUserTransfer = ""
  const onSubmitOrder = ({formData}, e) =>{ 
    let transfer = submitOrder(formData, e, ordersList, dataTransfer, newUserTransfer, customersList)
    newUserTransfer = transfer[1]
    dataTransfer = transfer[0]
  }
  const onSubmitCustomer = ({formData}, e) =>{ 
    dataTransfer = submitCustomer (formData, e, customersList,dataTransfer)
    document.getElementById("submit-form").reset();
  }
  let onSubmit
  let handleSave
  if (props.onSubmitFunction === "orders"){
    onSubmit = onSubmitOrder
    handleSave = orderHandleSave
  }
  if (props.onSubmitFunction === "customers"){
    onSubmit = onSubmitCustomer
    handleSave = customerHandleSave
  }
    return (
        <> 
          <Button variant={props.color} className=" btn-card btn-lg" onClick={handleShow}>{props.buttonTitle}</Button>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{props.modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
            <Form schema={schema} formData={""} onSubmit={onSubmit} id="submit-form">
            <Button variant="secondary" type="submit" >
                Confirmer et ajouter
              </Button>
              </Form>

        </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleSave}>
                Sauvgarder
              </Button>
            </Modal.Footer>
          </Modal>
        </>  
    );
}
PopupForm.propTypes = {
  modalTitle: PropTypes.string,
  onSubmitFunction: PropTypes.string,
  schema: PropTypes.array,
  requiredFields: PropTypes.array,
  additionalProperties: PropTypes.array,
  color: PropTypes.string,
  buttonTitle: PropTypes.string
};

export default PopupForm;
