
import { FaRegEnvelope, FaFacebookSquare ,FaTwitterSquare, FaLinkedin,FaInstagram, FaMapMarkerAlt,FaPhoneSquareAlt} from "react-icons/fa";
import './Footer.scss';
const Footer = () => <footer className="footer">
    <div className="footer-center col-md-4 col-sm-6">
    <p className="about">
        <span> Qui sommes nous?</span> Entreprise de livraison à domicile qui s&lsquo;adapte à vos besoins. Livrer plus rapidement à vos clients et finalisez vos transactions.
    </p>
    <div className="icons">
        
        <a href="https://pages.facebook.com/CalirexTunisie"><FaFacebookSquare className="ct-icon"/></a>
        <a href="https://twitter.com/CalirexTunisie"><FaTwitterSquare className="ct-icon"/></a>
        <a href="https://linkedin.com/company/calirex-tunisie"><FaLinkedin className="ct-icon"/></a>
        <a href="https://www.instagram.com/calirex.tunisie"><FaInstagram className="ct-icon"/></a>
    </div>
    </div>
    <div className="footer-center">
    <div>
        <a><FaMapMarkerAlt className="ct-icon"/>
        <p><span> 7 avenue habib bourguiba, Mornaguia</span> Manouba, Tunisie</p></a>
    </div>
    <div>
        <FaPhoneSquareAlt className="ct-icon"/>
        <p> (+216) 29 331 715</p>
    </div>
    <div>
        <FaRegEnvelope className="ct-icon"/>
        <p><a href="#"> contact@calirextn.com</a></p>
    </div>
    </div>
    <div className="footer-right">
    <h2 className="mea-text"> Calirex <span>Tunisie</span></h2>
    <p className="menu">
        <a href="/"> Accueil</a> |
        <a href="/pricing"> Tarifs</a> |
        <a href="/carrieres"> Carrières</a> |
        {/* <a href="/tracking"> Suivre un colis</a> */}
    </p>
    <p className="name"> Calirex Tunisie &copy; 2021</p>
    </div>
	<div className="footer-bottom">
		Copyright © 2021 calirex tunisie. Tous droits réservés.
	</div>
    </footer>

export default Footer
