import React from "react";
import PropTypes from "prop-types";
import './Video.scss';
const Video = ({ embedId }) => (
	<div className="video-responsive">
		<iframe
		src={`https://www.youtube.com/embed/${embedId}`}
		frameBorder="0" 
		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
		allowFullScreen
		/>
	</div>
);

Video.propTypes = {
	embedId: PropTypes.string.isRequired
};

export default Video;
