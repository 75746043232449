import React from 'react';
import { useHistory } from "react-router-dom";
import { getUser, getToken } from '../../api/Common';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

function Notifications() {
  const user = getUser();
  let history = useHistory();
  // handle click event of logout button
  if (!getToken()){
	history.push("/login")
  }
  const products = []
  const columns = [
	{
		dataField: 'order_id',
		text: 'Numéro de commande'
	}, 
	{
		dataField: 'package_id',
		text: 'Numéro du colis'
	}, 
	{
		dataField: 'weight_g',
		text: 'Poids'
	},
	{
		dataField: 'name',
		text: 'Nom du client'
	}, 
	{
		dataField: 'email',
		text: 'Adresse mail'
	},

	{
		dataField: 'state',
		text: 'État'
	},
	{
		dataField: 'postal_code',
		text: 'Code postale'
	}, 
	{
		dataField: 'price_base',
		text: 'Prix du produit'
	}, 
	{
		dataField: 'base_price_delivery',
		text: 'Prix de livraison'
	}, 
	{
		dataField: 'status',
		text: 'statut'
	}


];
  return (
    <BootstrapTable keyField='id' className='bootstrap-data-table' data={ products } columns={ columns } pagination={ paginationFactory() } />
  );
}
export default Notifications;



