import { Col, Row } from "react-bootstrap";
import './IntroCard.scss';
function IntroCard() {
    return (
        <> 
			<div className="bg-image p-5  mb-5 text-white align-items-center d-flex">
				<Col md={6} className="cover-text-bloc has-text-centered has-text-left-touch">
						<h1 className="is-size-30 is-size-15-mobile is-white">
						{'On s\'engage '}
						<br/>
						{'à améliorer'}
						<br/>
						{'votre expérience'}
						</h1>
						{/* <a type="button" href="/tracking" className="is-size-15 btn btn-primary"> Suivre un colis</a> */}
				</Col>
				<img src="https://calirextn.com/assets/images/truck.svg" className="cover-icon is-hidden-touch"/>
				<img src='https://calirextn.com/assets/images/building-shadow.webp' className="cover-image is-hidden-touch"/>
			</div>
			
        </>  
    );
}
export default IntroCard;
