// return the user data from the session storage
import axios from 'axios';
export const getOrders = (token, resolve, reject) => {
    axios.get('https://dqnhq15t16.execute-api.eu-west-3.amazonaws.com/prod/orders?pathvar='+token.replace(/\W/g, '-')+'orders').then(response => {
        if(response.data.statusCode === 200) {
            resolve(response.data.body)
            return(response.data.body)
        }else{
         
            resolve(0)
            return 0
        }
    }).catch(error => {
        resolve(0)
        return 0
    });
}
export const getCustomers = (token, resolve, reject) => {
  axios.get('https://dqnhq15t16.execute-api.eu-west-3.amazonaws.com/prod/customers?pathvar='+token.replace(/\W/g, '-')+'customers').then(response => {
      if(response.data.statusCode === 200) {
          resolve(response.data.body)
          return(response.data.body)
      }else{
       
          resolve(0)
          return 0
      }
  }).catch(error => {
      resolve(0)
      return 0
  });
}
export const setOrders = (data, resolve, reject) => {
  axios.post('https://dqnhq15t16.execute-api.eu-west-3.amazonaws.com/prod/orders',data).then(response => {
      if(response.data.statusCode === 200) {
          resolve(response.data.body)
          return(response.data.body)
      }else{
       
          resolve(0)
          return 0
      }
  }).catch(error => {
      resolve(0)
      return 0
  });
}
export const setCustomers = (data, resolve, reject) => {
  console.log('https://dqnhq15t16.execute-api.eu-west-3.amazonaws.com/prod/customers',data)
  axios.post('https://dqnhq15t16.execute-api.eu-west-3.amazonaws.com/prod/customers',data).then(response => {
      if(response.data.statusCode === 200) {
          resolve(response.data.body)
          return(response.data.body)
      }else{
       
          resolve(0)
          return 0
      }
  }).catch(error => {
      resolve(0)
      return 0
  });
}

const userAttributes = ["phonenumber", "companyname", "landline", "plan", "address", "postalcode", "email", "country", "state", "city", "lastname", "countrycode", "firstname", "id"]
export const getUser = () => {
  const userStr = sessionStorage.getItem('firstname');
  
  if (userStr) return userStr;
  else return null;
}

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('orders')
  sessionStorage.removeItem('customers')
  for (let i = 0; i < userAttributes.length; i++){
    sessionStorage.removeItem(userAttributes[i])
  }
}
export const getUserIds = () =>{
  const users = JSON.parse(sessionStorage.getItem("customers"))
  let ids = []
  for (let i=0; i<users.length; i++){
    if(users[i].memory==1){
      ids.push(users[i].id)
    }
  }
  return ids
}
// set user session
export const setUserSession = (user) => {
  sessionStorage.setItem('token', user.id);
  for (let i = 0; i < userAttributes.length; i++){
    sessionStorage.setItem(userAttributes[i], user[userAttributes[i]]);
  }
  setOrdersList(user.id)
  setCustomersList(user.id)
}
export const setOrdersList = (token) => {
  new Promise((resolve,reject) => {
    getOrders(token, resolve, reject)
  }).then((list) =>{
    if(list){
      console.log(list)
      sessionStorage.setItem('orders', JSON.stringify(list.Items));
    }
    else{
      sessionStorage.setItem('orders', '[]');
    }
    
  })
  
  
}
export const setCustomersList = (token) => {
  new Promise((resolve,reject) => {
    getCustomers(token, resolve, reject)
  }).then((list) =>{
    if(list){
      console.log("here")
      console.log(list)
      sessionStorage.setItem('customers', JSON.stringify(list.Items));
    }
    else{
      console.log("empty")
      sessionStorage.setItem('customers', '[]');
    }
    
  })
  
  
}
export const updateOrderList = (token,rawdata) => {
  console.log(rawdata)
  new Promise((resolve,reject) => {
    setOrders(rawdata, resolve, reject)
  }).then((list) =>{
    if(list){
      setOrdersList(token)
      console.log(list)
    }
    else{
      alert("Une erreur est survenue. Veuillez réessayer ultérieurement.")
    }
    
  })
  
  
}
export const updateCustomersList = (token,rawdata) => {
  new Promise((resolve,reject) => {
    setCustomers(rawdata, resolve, reject)
  }).then((list) =>{
    if(list){
      setCustomersList(token)
    }
    else{
      alert("Une erreur est survenue. Veuillez réessayer ultérieurement.")
    }
    
  })
  
  
}
export const getOrderslist = () => {
  const list = JSON.parse(sessionStorage.getItem("orders"))
  let items = []
  for (let i=0; i<list.length; i++){
    if(list[i].memory==1){
      items.push(list[i])
    }
  }
    return (JSON.stringify(items))
}
export const getCustomerslist = () => {
  const list = JSON.parse(sessionStorage.getItem("customers"))
  let items = []
  for (let i=0; i<list.length; i++){
    if(list[i].memory==1){
      items.push(list[i])
    }
  }
    return (JSON.stringify(items))
}
export const getUserPlan = () => {
  return (sessionStorage.getItem("plan"))
}

export const getUserSession = () => {
  const userInfo = []
  for (let i = 0; i < userAttributes.length-1; i++){
    if(!sessionStorage.getItem(userAttributes[i])){
      return null
    }
    userInfo.push(sessionStorage.getItem(userAttributes[i]))
    
  }
  const retVal = [userAttributes.slice(0, 13),userInfo]
  return retVal
}