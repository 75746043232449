import './App.scss';
import Home from './components/home/Home';

import HeaderOut from './components/header/HeaderLoggedOut';
import Footer from './components/footer/Footer';
import Prices from './components/prices/Prices';
import Profile from './components/profile/Profile';
import Orders from './components/orders/Orders';
import Notifications from './components/notifications/Notifications';
import Customers from './components/customers/Customers';
import Bills from './components/bills/Bills';
import ContactUs from './components/contactus/ContactUs';
import Tracking from './components/tracking/Tracking';
import Dashboard from './components/dashboard/Dashboard';
import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import axios from 'axios';
import { getToken, removeUserSession, setUserSession } from './api/Common';
function App() {
	const [authLoading, setAuthLoading] = useState(true);

	useEffect(() => {
		const token = getToken();
		if (!token) {
		return;
		}
		axios.get(`https://dqnhq15t16.execute-api.eu-west-3.amazonaws.com/prod/user?operation=2&&authorizationToken=${token}`).then(response => {
		if(response.data.statusCode === 200) {
			setUserSession(response.data.body);
			setAuthLoading(false);
		}else{	
			removeUserSession();
			setAuthLoading(false);
		}
		
		}).catch(error => {
		removeUserSession();
		setAuthLoading(false);
		});
	}, []);
	let header = <></>
		header = <HeaderOut bg="secondary"/>
	
	return (
		<Router>
			<div className="App">
				{header}
				<Switch>
					<Route path="/tracking">
						<Tracking />
					</Route>
					<Route path="/pricing">
						<Prices />
					</Route>

					<Route path="/dashboard">
						<Dashboard/>
					</Route>
					<Route path="/profile">
						<Profile/>
					</Route>
					<Route path="/customers">
						<Customers/>
					</Route>
					<Route path="/bills">
						<Bills/>
					</Route>
					<Route path="/notifications">
						<Notifications />
					</Route>
					<Route path="/orders">
						<Orders />
					</Route>
					<Route path="/contact-us">
						<ContactUs />
					</Route>
					<Route path="/">
						<Home/>
					</Route>
				</Switch>
				<Footer/>
			</div>
		</Router>
	);
}

export default App;
