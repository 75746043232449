
import {getToken} from '../../api/Common';
import {useHistory} from "react-router-dom";
import {Button} from 'react-bootstrap';
function Profile() {
	let history = useHistory();
	// handle click event of logout button
	if (!getToken()){
		history.push("/login")
	}
    //const userInfo = getUserSession()
    //console.log(userInfo)
	return(
        <div className="container rounded bg-white mt-5 mb-5">
            <div className="row">
                <div className="col-md-3 border-right">
                    <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                        <img className="rounded-circle mt-5" width="150px" src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"/>
                    </div>
                </div>
                <div className="col-md-5 border-right">
                    <div className="p-3 py-5">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className="text-right">Profile Settings</h4>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6"><label className="labels">Nom</label>
                                <input type="text" className="form-control" placeholder="first name" value=""/>
                            </div>
                            <div className="col-md-6"><label className="labels">Prénom</label>
                                <input type="text" className="form-control" value="" placeholder="surname"/>
                            </div>
                            <div className="col-md-12">
                                <label className="labels">Adresse mail</label>
                                <input type="text" className="form-control" placeholder="enter email id" value=""/>
                            </div>
                            <div className="col-md-12">
                                <label className="labels">Société</label>
                                <input type="text" className="form-control" placeholder="enter email id" value=""/>
                            </div>
                            <div className="col-md-12">
                                <label className="labels">Programme</label>
                                <input type="text" className="form-control" placeholder="enter email id" value=""/>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12"><label className="labels">Numéro de téléphone</label>
                                <input type="text" className="form-control" placeholder="enter phone number" value=""/>
                            </div>
                            <div className="col-md-12">
                                <label className="labels">Adresse</label>
                                <input type="text" className="form-control" placeholder="enter address line 1" value=""/>

                            </div>
                            <div className="col-md-12">
                                <label className="labels">Complément d&#39;adresse</label>
                                <input type="text" className="form-control" placeholder="enter address line 2" value=""/>

                            </div>
                            <div className="col-md-12">
                                <label className="labels">Code postal</label>
                                <input type="text" className="form-control" placeholder="enter address line 2" value=""/>
                                
                            </div>
                            <div className="col-md-12">
                                <label className="labels">Ville</label>
                                <input type="text" className="form-control" placeholder="enter address line 2" value=""/>
                            </div>

                            <div className="col-md-6">
                                <label className="labels">Pays</label>
                                <input type="text" className="form-control" placeholder="country" value=""/>
                            </div>
                            <div className="col-md-6">
                                <label className="labels">État</label>
                                <input type="text" className="form-control" value="" placeholder="state"/>
                            </div>
                        </div>
                        <div className="mt-5 text-center">
                            <button className="btn btn-primary profile-button" type="button">Sauvegarder</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="p-3 py-5">
                        <div className="row">
                            <div className="col-md-6 text-right"
                                style={{ textAlign: 'end'}}
                            >
                                <Button variant="primary" size="lg">Modifier</Button>
                            </div>
                            <div className="col-md-6"
                                style={{ textAlign: 'start'}}
                            >
                                <Button variant="danger" size="lg">Supprimer</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
}

export default Profile;






